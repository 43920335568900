import { AviaPassengerType } from '@websky/graphql';
import { MAX_PASSENGERS_COUNT } from '../../../store/passengers/reducers';
export var isInfant = function (passenger) {
    var infantTypes = [AviaPassengerType.INF, AviaPassengerType.INS];
    return (infantTypes.includes(passenger === null || passenger === void 0 ? void 0 : passenger.code) ||
        infantTypes.includes(passenger === null || passenger === void 0 ? void 0 : passenger.parentCode));
};
export var canBeIncrease = function (passenger, config, passengers, totalCount) {
    if (totalCount >= MAX_PASSENGERS_COUNT) {
        return false;
    }
    if (passenger.maxCount) {
        return passengers[passenger.code] < passenger.maxCount;
    }
    if (!passenger.isAdult) {
        var adultCount = config.reduce(function (count, current) { return count + (current.isAdult ? passengers[current.code] || 0 : 0); }, 0);
        if (isInfant(passenger) && passengers[passenger.code] >= adultCount) {
            return false;
        }
        else if (adultCount <= 0) {
            if (passenger.maxCountWithoutAdult && passengers[passenger.code] < passenger.maxCountWithoutAdult) {
                return true;
            }
            return false;
        }
    }
    return true;
};
export var canBeDecrease = function (passenger, config, passengers) {
    var passengersRequireAdult = config.filter(function (pass) {
        return !pass.isAdult &&
            passengers[pass.code] &&
            (!pass.maxCountWithoutAdult || passengers[pass.code] > pass.maxCountWithoutAdult);
    });
    if (passengers[passenger.code] <= 0) {
        return false;
    }
    else if (passenger.isAdult && config.some(function (type) { return !type.isAdult && passengers[type.code] > 0; })) {
        var adultsCount = config.reduce(function (count, current) { return count + (current.isAdult ? passengers[current.code] || 0 : 0); }, 0);
        if (adultsCount <= 1 && passengersRequireAdult.length) {
            return false;
        }
    }
    return true;
};
export var isRoutesMatch = function (route, segment) {
    var _a, _b;
    return ((route.arrival === '*' || ((_a = segment.arrival) === null || _a === void 0 ? void 0 : _a.iata) === route.arrival) &&
        (route.departure === '*' || ((_b = segment.departure) === null || _b === void 0 ? void 0 : _b.iata) === route.departure));
};
