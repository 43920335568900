export var Currency;
(function (Currency) {
    Currency["USD"] = "USD";
    Currency["RUB"] = "RUB";
    Currency["EUR"] = "EUR";
    Currency["KZT"] = "KZT";
    Currency["AMD"] = "AMD";
    Currency["UZS"] = "UZS";
    Currency["AZN"] = "AZN";
    Currency["TRY"] = "TRY";
    Currency["RSD"] = "RSD";
    Currency["GEL"] = "GEL";
    Currency["ILS"] = "ILS";
    Currency["CNY"] = "CNY";
    Currency["BYN"] = "BYN";
})(Currency || (Currency = {}));
export var CurrencySymbols;
(function (CurrencySymbols) {
    CurrencySymbols["USD"] = "$";
    CurrencySymbols["RUB"] = "\u20BD";
    CurrencySymbols["EUR"] = "\u20AC";
    CurrencySymbols["SAR"] = "\uFDFC";
    CurrencySymbols["AED"] = "\u062F.\u0625";
    CurrencySymbols["AMD"] = "\u058F";
    CurrencySymbols["UZS"] = "so\u2019m";
    CurrencySymbols["AZN"] = "\u20BC";
    CurrencySymbols["TRY"] = "\u20BA";
    CurrencySymbols["RSD"] = "din";
    CurrencySymbols["GEL"] = "\u20BE";
    CurrencySymbols["ILS"] = "\u20AA";
    CurrencySymbols["KZT"] = "\u20B8";
    CurrencySymbols["CNY"] = "\u00A5";
    CurrencySymbols["BYN"] = "Br";
})(CurrencySymbols || (CurrencySymbols = {}));
export var PassengerType;
(function (PassengerType) {
    PassengerType["Adult"] = "ADT";
    PassengerType["Teenager"] = "PDA";
    PassengerType["Teenager_F7"] = "YTH";
    PassengerType["Retiree_F7"] = "SRC";
    PassengerType["Child"] = "CLD";
    PassengerType["Teenager_GCF"] = "GCF";
    PassengerType["Infant"] = "INF";
    PassengerType["InfantWithSeat"] = "INS";
    PassengerType["InfantWithSeat_RVT"] = "\u0420\u0412\u0422";
    PassengerType["YoungAdult"] = "\u041C\u041B\u0410";
    PassengerType["RetireeM"] = "SCM";
    PassengerType["RetireeF"] = "SCF";
    PassengerType["Adult_D2"] = "\u0410\u0410\u0410";
    PassengerType["Child_D2"] = "\u0420\u0411\u0413";
    PassengerType["Infant_D2"] = "\u0420\u041C\u0413";
    PassengerType["InfantWithSeat_D2"] = "\u0420\u0412\u0413";
    PassengerType["Retiree_D2"] = "\u0426\u041F\u0410";
})(PassengerType || (PassengerType = {}));
