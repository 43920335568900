import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { CartService } from '../../Cart/types';
import CartItemOptionAdapter from '../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import { filterServicesByRfisc, groupServices } from '../../Cart/utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import BaggageIcon from '../../Cart/BaggageIcon/BaggageIcon';
import ServiceSummaryDivider from '../components/ServiceSummaryDivider/ServiceSummaryDivider';
import { getActualProduct } from '../utils';
import { useConfig } from '../../context';
import { isPetBaggage } from '../../Checkout/utils';
var BaggageSummary = function (props) {
    var availableAnimal = useConfig().global.servicesAvailable.animal;
    var onServiceDeleteHandler = function (travellerId, serviceId, segmentId, allowedSegments, allSegments) {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Baggage, {
            passengerId: travellerId,
            serviceId: serviceId,
            segmentId: segmentId,
            allSegments: allSegments,
            allowedSegments: allowedSegments
        });
    };
    var filterGroupService = React.useCallback(function (service) {
        var baggageService = service;
        if (availableAnimal) {
            var petBaggage = isPetBaggage(baggageService);
            return props.isAnimalBaggage ? petBaggage : !petBaggage;
        }
        return true;
    }, [props.isAnimalBaggage, availableAnimal]);
    var baggage = React.useMemo(function () {
        var allSegmentsOptions = [], otherSegments = [];
        props.travellers.map(function (traveller) {
            var filteredTraveller = filterServicesByRfisc(traveller, props.services);
            var baggages = groupServices(props.services, filteredTraveller, traveller.id, OrderAdditionalServiceGdsServiceServiceType.Baggage, props.flight.segments, filterGroupService);
            Object.keys(baggages[traveller.id] || {}).forEach(function (segment) {
                var _a, _b;
                var items = [];
                var isAllSegments = segment === 'allSegments';
                if (!props.isAnimalBaggage) {
                    (_a = baggages[traveller.id][segment].included) === null || _a === void 0 ? void 0 : _a.forEach(function (included) {
                        var service = included.service;
                        items.push({
                            id: included.serviceId,
                            segment: segment,
                            count: included.count,
                            name: "".concat(included.count, "x ").concat(service.name).concat(service.baggageWeight ? ", ".concat(service.baggageWeight) : ''),
                            included: true,
                            icon: React.createElement(BaggageIcon, { baggageType: included.service['baggageType'] })
                        });
                    });
                }
                (_b = baggages[traveller.id][segment].additional) === null || _b === void 0 ? void 0 : _b.forEach(function (additional) {
                    var service = additional.service;
                    var actualProduct = getActualProduct(additional.products);
                    items.push({
                        id: additional.serviceId,
                        count: additional.count,
                        name: "".concat(additional.count, "x ").concat(service.name).concat(service.baggageWeight ? ", ".concat(service.baggageWeight) : ''),
                        price: additional.service.price,
                        included: additional.confirmedCount === additional.count,
                        icon: !props.isAnimalBaggage ? React.createElement(BaggageIcon, { baggageType: service.baggageType }) : null,
                        status: actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.status,
                        onDelete: function () {
                            return onServiceDeleteHandler(traveller.id, additional.serviceId, segment, service.allowedSegments, isAllSegments);
                        }
                    });
                });
                if (isAllSegments) {
                    if (!!items.length) {
                        var allSegmentItems = items.map(function (item) {
                            var _a;
                            return (__assign(__assign({}, item), { price: __assign(__assign({}, item.price), { amount: ((_a = item.price) === null || _a === void 0 ? void 0 : _a.amount) * item.count * props.flight.segments.length }) }));
                        });
                        allSegmentsOptions.push({
                            items: allSegmentItems,
                            allSegments: true,
                            headerType: 'travellerName',
                            traveller: props.travellersById.get(traveller.id)
                        });
                    }
                }
                else {
                    var selectedSegment = props.flight.segments.find(function (seg) { return seg.segment.id === segment; });
                    if (!!items.length) {
                        otherSegments.push({
                            items: items,
                            direction: selectedSegment.segment,
                            headerType: 'travellerName',
                            traveller: props.travellersById.get(traveller.id)
                        });
                    }
                }
            });
        });
        return {
            allSegmentsOptions: allSegmentsOptions,
            otherSegments: otherSegments
        };
    }, [props.flight, props.travellers, props.services]);
    var content = React.useMemo(function () {
        if (!baggage.allSegmentsOptions.length && !baggage.otherSegments.length) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            !!baggage.allSegmentsOptions.length && (React.createElement(ServiceSummaryDivider, null, __spreadArray([], __read(baggage.allSegmentsOptions), false).map(function (group, index) { return (React.createElement(CartItemOptionAdapter, { option: group, key: index })); }))),
            !!baggage.otherSegments.length && (React.createElement(ServiceSummaryDivider, null, __spreadArray([], __read(baggage.otherSegments), false).map(function (group, index) { return (React.createElement(CartItemOptionAdapter, { option: group, key: index })); })))));
    }, [baggage]);
    return props.children(content);
};
export default BaggageSummary;
